import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter, isEmptyOrSpaces, newGuid } from '../../helpers/generalUtils';
import Select from 'react-select';
import { ApplicationsResponseTable } from './ApplicationsResponseTable';
import { ApplicationSectionHeader } from './ApplicationSectionHeader';
import { ApplicationTest } from './ApplicationTest';
import { idpActions, tenantInfoActions } from '../../actions';

function Applications(props) {

    const [inputs, setInputs] = useState({});
    const [state, setState] = useState('list');
    const [renamingApplicationId, setRenamingApplicationId] = useState('');
    const [editingApplicationId, setEditingApplicationId] = useState('');
    const [editingResponseApplicationId, setEditingResponseApplicationId] = useState('');
    const [deletingApplicationId, setDeletingApplicationId] = useState('');
    const [testingApplicationId, setTestingApplicationId] = useState('');
    const [applications, setApplications] = useState([]);
    const [subjectNameIdentifierOptionsGrouped, setSubjectNameIdentifierOptionsGrouped] = useState([]);
    const [contactAttributesOptionsGrouped, setContactAttributesOptionsGrouped] = useState([]);
    const [accountAttributesOptionsGrouped, setAccountAttributesOptionsGrouped] = useState([]);
    const [displayNameRequiredValidation, setDisplayNameRequiredValidation] = useState(false);
    const [displayDuplicateValidation, setDisplayDuplicateValidation] = useState(false);
    const [displayMethodRequiredValidation, setDisplayMethodRequiredValidation] = useState(false);
    const nameIdFormatDefault = 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified';
    const [metadataUrl, setMetadataUrl] = useState('');
    const [loadingMetadata, setLoadingMetadata] = useState(false);
    const [identityProviderMetadataXML, setIdentityProviderMetadataXML] = useState('');
    const [failedMetadataUpdate, setFailedMetadataUpdate] = useState(false);

    useEffect(() => {
        setApplications(props.settings);
    }, [props.settings]);

    useEffect(() => {
        setSubjectNameIdentifierOptionsGrouped(convertCrmSchemaToOptions(props.crmSchema, 'subjectNameIdentifier', 'contact'));
        setContactAttributesOptionsGrouped(convertCrmSchemaToOptions(props.crmSchema, 'attributes', 'contact'));
        setAccountAttributesOptionsGrouped(convertCrmSchemaToOptions(props.crmSchema, 'attributes', 'account'));
    }, [props.crmSchema]);

    useEffect(() => {
        if (!props.unsavedChanges && editingApplicationId)
        {
            let application = applications.find(app => app.id === editingApplicationId);
            if (application.method == 'saml')
                generateMetadataXml(application);
        }
    }, [props.unsavedChanges]);

    useEffect(() => {
        if (displayNameRequiredValidation) setDisplayNameRequiredValidation(false);
        if (displayDuplicateValidation) setDisplayDuplicateValidation(false);
        if (displayMethodRequiredValidation) setDisplayMethodRequiredValidation(false);
    }, [inputs, state]);

    useEffect(() => {
        props.onDisplayMainNavChange(state != 'edit' && state != 'test' && state != 'response');
    }, [state]);

    function handleAddNewApplication(e) {
        setState('add');
    }    

    function handleRenameApplication(e, id, name) {
        setState('rename');
        setInputs(inputs => ({ ...inputs, name: name }));
        setRenamingApplicationId(id);
    }   

    function handleEditApplication(e, id) {
        setState('edit');
        let application = applications.find(app => app.id === id);
        setInputs(application);

        setEditingApplicationId(id);

        if (application.method == 'saml')
            generateMetadataXml(application);
    }

    function handleEditingResponseApplication(e, id) {
        setState('response');
        setInputs({response : applications.find(app => app.id === id).response });
        setEditingResponseApplicationId(id);
    }

    function handleTestApplication(e, id) {
        setState('test');
        setTestingApplicationId(id);
    }

    function handleDeleteApplication(e, id) {
        setState('delete');
        setDeletingApplicationId(id);
    }

    function handleConfirmDeleteApplication(e) {
        setState('list');

        // Delete Application
        let apps = applications.filter(app => app.id != deletingApplicationId);
        setApplications(apps);

        // Update parent's state
        handleSave(e, apps);
    }

    function handleCancelDeleteApplication(e) {
        setState('list');
        setDeletingApplicationId('');
    }

    function handleSaveAdd(e) {
        let name = inputs.name.trim();
        let nameFailed = isEmpty('name', name) || isDuplicate(name);
        let methodFailed = isEmpty('method', inputs.method);
        if (nameFailed || methodFailed) return;

        setState('list');

        // Add Application
        let newApp = { id: newGuid(), name: name, method: inputs.method, integrationEnabled: true };
        if (inputs.method == 'saml')
        {
            let entityId = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/api/idp/sso/${name.toLowerCase().replace(/ /g, "-")}`;
            newApp.entityId = entityId;
            newApp.ssoEndpoint = entityId;
            newApp.nameIdFormat = nameIdFormatDefault;
            newApp.response = { subjectNameIdentifier: "contactid" };
            newApp.messageFormat = "1"; // Signed
            newApp.assertionFormat = "0"; // Unsigned
        }

        applications.push(newApp);

        // Sort alphabetically
        applications.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

        setApplications(applications);

        // Clear inputs
        setInputs([]);

        // Update parent's state
        handleSave(e, applications);
    }

    function handleSaveRename(e) {
        let name = inputs.name.trim();
        if (isEmpty('name', name)) return;
        if (isDuplicate(name)) return;

        setState('list');

        // Rename Application
        let apps = applications.map(samlApplication => { return (samlApplication.id == renamingApplicationId ? {...samlApplication, name: name } : samlApplication) });
        setApplications(apps);

        // Clear inputs
        setInputs([]);

        // Update parent's state
        handleSave(e, apps);
    }

    function isEmpty(field, value) {
        if (isEmptyOrSpaces(value)) {
            if (field == 'name') setDisplayNameRequiredValidation(true);
            if (field == 'method') setDisplayMethodRequiredValidation(true);
            return true;
        }

        return false;
    }

    function isDuplicate(name) {
        if (applications.filter(app => app.name.toLowerCase() == name.toLowerCase() && app.id != renamingApplicationId).length > 0) {
            setDisplayDuplicateValidation(true);
            return true;
        }

        return false;
    }

    function handleCancel(e) {
        setState('list');
     
        // Clear inputs
        setInputs([]);
    }

    function handleInputChange(e) {
        var { name, value } = e.target;

        if (e.target.type == "checkbox")
            value = e.target.checked;

        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleInputEditChange(e) {
        var { name, value } = e.target;

        if (e.target.type == "checkbox")
            value = e.target.checked;

        // Update Application settings
        let apps = applications.map(application => application.id == editingApplicationId ? 
                                    {...application, [name]: value}
                                    : application);
        setApplications(apps);

        props.onSave(e, apps);
    }

    function handleMetadataUrlChange(e) {
        setMetadataUrl(e.target.value);
    }

    function handleGetMetadataClick(e) {
        setLoadingMetadata(true);

        tenantInfoActions.getApplicationSPMetadata(metadataUrl)
        .then(response => { 
            if (response)
            {
                // Get Metadata from metadataUrl
                let apps = applications.map(application => application.id == editingApplicationId ? 
                    {...application, serviceProviderMetadata: response}
                    : application);

                setApplications(apps);

                props.onSave(e, apps);
            }
            else
                setFailedMetadataUpdate(true);

            setLoadingMetadata(false);
        });
    }

    function handleKeyDownGetMetadata(e) {
        if (e.key === 'Enter') 
        {
            handleGetMetadataClick(e);
            e.target.blur();
        }
    }

    function handleCloseMetadataFail(e) {
        setFailedMetadataUpdate(false);
    }

    function handleReponseChange(e, property, value) {
        // Update Application settings
        let apps = applications.map(application => application.id == editingResponseApplicationId ? 
                                    {...application, response: {...application.response, [property]: value}}
                                    : application);        

        setApplications(apps);

        props.onSave(e, apps);
    }    

    function handleUnspecified(e) {
        let apps = applications.map(application => application.id == editingApplicationId ? 
            {...application, nameIdFormat: nameIdFormatDefault}
            : application);

        setApplications(apps);

        props.onSave(e, apps);}

    function handleSave(e, apps) {
        props.onSave(e, apps);

        setRenamingApplicationId('');
        setEditingApplicationId('');
        setEditingResponseApplicationId('');
        setDeletingApplicationId('');

        // Clear inputs
        setInputs([]);
    }       

    function convertCrmSchemaToOptions(crmSchema, type, entity) {
        const frequentlyUsedFields = (type == 'subjectNameIdentifier' ? ['emailaddress1', 'contactid', 'pa_contactnumber'] : 
                                     (entity == 'contact' ? ['emailaddress1', 'firstname', 'lastname', 'fullname', 'pa_webloginname', 'pa_member', 'pa_contactnumber',
                                     'contactid', 'jobtitle', 'pa_professionaldesignation', 'address1_line1', 'address1_line2', 'address1_line3', 
                                     'address1_postalcode', 'address1_city', 'address1_stateorprovince', 'address1_county', 'address1_country', 
                                     'accountid', 'defaultpricelevelid', 'pa_profileimage'] : ['accountid', 'name', 'accountnumber']));

        frequentlyUsedFields.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1)

        let groupedOptions = [];

        // Frequently Used
        let group = {};
        group.label = 'Frequently Used';
        group.options = [];
        group.options = frequentlyUsedFields.map(frequentlyUsedField => {
            let objEntity = crmSchema.find(e => e.EntityName == entity);
            if (objEntity) {
                let field = objEntity.Fields.find(f => f.Name == frequentlyUsedField);
                if (field) {
                    return {
                        value: `${field.Name}`,
                        label: `${field.Label} (${field.Name})`,
                        type: field.Type
                    };
                }
            }
        });
        if (type != 'subjectNameIdentifier' && entity == 'contact')
        {
            group.options.push({
                value: `webroles`,
                label: `Web Roles`
            });
        }
        groupedOptions.push(group);

        let objEntity = crmSchema.find(e => e.EntityName == entity);
        if (objEntity) {
            let group = {};
            group.label = objEntity.EntityName;
            group.options = [];
            let fields  = objEntity.Fields.filter(field => field.Label); 
            fields.sort((a, b) => (a.Label.toLowerCase() > b.Label.toLowerCase()) ? 1 : -1);
            fields.forEach(field => {
                group.options.push({
                    value: `${field.Name}`,
                    label: `${field.Label} (${field.Name})`,
                    type: field.Type
                });
            });
            if (type != 'subjectNameIdentifier' && entity == 'contact')
            {
                group.options.push({
                    value: `webroles`,
                    label: `Web Roles`
                });
            }
            groupedOptions.push(group);
        }

        return groupedOptions;
    }  

    function generateMetadataXml(application) {
        let ssoEndpointArray = application.ssoEndpoint.split('/');

        idpActions.metadata(ssoEndpointArray[ssoEndpointArray.length - 1]).then(metadata => {
            setIdentityProviderMetadataXML(metadata);
        });
    }
    
    let editApp = applications.find(app => app.id == editingApplicationId);
    let editResponseApp = applications.find(app => app.id == editingResponseApplicationId);
    let testApp = applications.find(app => app.id == testingApplicationId);
    let deleteApp = applications.find(app => app.id == deletingApplicationId);
    
    return (
        <div className="clearfix">
            {state != 'edit' && state != 'response' &&  state != 'test' &&
            <>
                <h4 className="text-primary">Applications</h4>
                <hr />
                <table className="table mt-4 bg-white">
                    <tbody>
                        {(!applications || applications.length == 0) &&
                            <tr>
                                <td colspan="2" className="text-center p-3">
                                    No Applications to display.
                                </td>
                            </tr>
                        }
                        {applications && applications.length > 0 &&
                            applications.map(app => 
                            <tr>
                                <td className="p-3">
                                    {app.name}
                                    <span className="badge bg-secondary ms-2 d-inline">{app.method.toUpperCase()}</span>
                                </td>
                                <td className="text-end p-3">
                                    <a role="button" onClick={(e) => handleEditApplication(e, app.id)} className="text-primary me-4"><i className="fas fa-edit" title="Edit"></i></a>
                                    {app.method ==  'saml' && 
                                    <>
                                        <a role="button" onClick={(e) => handleEditingResponseApplication(e, app.id)} className="text-primary me-4"><i className="far fa-list-alt" title="Response"></i></a>
                                        <a role="button" onClick={(e) => handleTestApplication(e, app.id)} className="text-primary me-4"><i className="fas fa-play" title="Test"></i></a>
                                    </>
                                    }
                                    <a role="button" onClick={(e) => handleRenameApplication(e, app.id, app.name)} className="text-primary me-4"><i className="fas fa-i-cursor" title="Rename"></i></a>
                                    <a role="button" onClick={(e) => handleDeleteApplication(e, app.id)} className="text-danger"><i className="fas fa-trash-alt" title="Delete"></i></a>
                                </td>
                            </tr>
                            )
                        }
                    </tbody>
                </table>
                <button onClick={handleAddNewApplication} className="btn btn-primary float-end">Add New Application</button>
            </>
            }

            {(state == 'add' || state == 'rename') &&
            <>
            <div className="fade modal-backdrop show"></div>
            <div role="dialog" className="fade modal show" tabIndex="-1" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title m-3">Application</h5>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <input placeholder="Name" onChange={handleInputChange} value={inputs.name} className={"form-control" + (displayNameRequiredValidation || displayDuplicateValidation ? " is-invalid" : "")} type="textbox" id="name" name="name"></input>
                                {(displayNameRequiredValidation || displayDuplicateValidation) && 
                                <div className="invalid-feedback">
                                    {displayNameRequiredValidation && <>Name is required.</>}
                                    {displayDuplicateValidation && <>There's another Application named {inputs.name}. Please change Application Name and try again.</>}
                                </div>
                                }
                            </div>
                            {state == 'add' &&
                            <div className="mb-3">
                                <select value={inputs.method} onChange={handleInputChange} id="method" name="method" className={"form-select" + (!inputs.method ? " text-muted" : "") + (displayMethodRequiredValidation ? " is-invalid" : "")}>
                                    <option value='' disabled hidden selected>SSO Integration Method</option>
                                    <option value='token'>Token</option>
                                    <option value='saml'>SAML</option>
                                </select>
                                {displayMethodRequiredValidation &&
                                <div className="invalid-feedback">
                                    SSO Integration Method is required.
                                </div>
                                }
                            </div>
                            }
                        </div>
                        <div className="modal-footer text-end">
                            <button onClick={state == 'add' ? handleSaveAdd : handleSaveRename} className="btn btn-primary me-2">{capitalizeFirstLetter(state)}</button>
                            <button onClick={handleCancel} className="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }

            {state == 'delete' &&
            <>
            <div className="fade modal-backdrop show"></div>
            <div role="dialog" className="fade modal show" tabIndex="-1" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title m-3">Application</h5>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                Are you sure you want to delete <b><i>{deleteApp.name}</i></b> Application?
                            </div>
                        </div>
                        <div className="modal-footer text-end">
                            <button onClick={handleConfirmDeleteApplication} className="btn btn-primary me-2">Delete</button>
                            <button onClick={handleCancelDeleteApplication} className="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }

            {state == 'edit' &&  editApp && 
            <>
            <ApplicationSectionHeader app={editApp} section='Edit' onCancel={handleCancel} />
            <h5 className="text-primary">General</h5>
            <div className="mb-3">
                <div className="form-check form-switch">
                    <input checked={editApp.integrationEnabled} onChange={handleInputEditChange} className="form-check-input" type="checkbox" id="integrationEnabled" name="integrationEnabled"></input>
                    <label className="form-check-label" htmlFor="integrationEnabled">Integration enabled</label>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="theme" className="form-label">Theme</label>
                <select value={editApp.theme} onChange={handleInputEditChange} id="theme" name="theme" className={"form-select"+ (!editApp.theme ? " text-muted" : "")}>
                    <option value='' class="text-muted" selected>Site Theme</option>
                    {props.themes.map((theme) => { return <option value={theme.id}>{theme.name}</option> })}        
                </select>
            </div>
            {inputs.method == 'token' &&
            <>
            <hr />
            <h5 className="text-primary">Advanced</h5>
            <div className="mb-3">
                <label htmlFor="url" className="form-label">Url</label>
                <input type="text" value={editApp.url} onChange={handleInputEditChange} id="url" name="url" className="form-control" />
            </div>
            <div className="mb-3">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="attachTokenToTheReturnUrl" name="attachTokenToTheReturnUrl" checked={editApp.attachTokenToTheReturnUrl} onChange={handleInputEditChange}></input>
                    <label className="form-check-label" htmlFor="attachTokenToTheReturnUrl">Attach Token to the ReturnUrl</label>
                </div>
            </div>
            </>
            }
            {inputs.method == 'saml' &&
                <>
                <hr />
                <h5 className="text-primary">Service Provider</h5>
                <div className="mb-3">
                    <label htmlFor="serviceProviderMetadata" className="form-label">Service Provider Metadata</label>
                    <textarea value={!loadingMetadata ? editApp.serviceProviderMetadata : 'Loading...'} onChange={handleInputEditChange} disabled={loadingMetadata} id="serviceProviderMetadata" name="serviceProviderMetadata" rows="20" className="form-control">
                    </textarea>
                </div>
                <div className="mb-3">
                    <label htmlFor="metadataUrl" className="form-label">Metadata URL</label>
                    <div className="input-group">
                        <input type="text" value={metadataUrl} onChange={handleMetadataUrlChange} onKeyDown={handleKeyDownGetMetadata} id="metadataUrl" name="metadataUrl" className="form-control" />
                        <button onClick={handleGetMetadataClick} disabled={loadingMetadata} className="btn btn-primary">
                            Get Metadata
                        </button>
                    </div>
                </div>
                <hr />
                <h5 className="text-primary">Identity Provider</h5>
                <div className="mb-3">
                    <label htmlFor="entityId" className="form-label">EntityId</label>
                    <input type="text" value={editApp.entityId} onChange={handleInputEditChange} id="entityId" name="entityId" className="form-control" />
                </div>
                <div className="mb-3">
                    <label htmlFor="ssoEndpoint" className="form-label">SSO Endpoint</label>
                    <input type="text" value={editApp.ssoEndpoint} onChange={handleInputEditChange} id="ssoEndpoint" name="ssoEndpoint" className="form-control" />
                </div>
                <div className="mb-3">
                    <label htmlFor="nameIdFormat" className="form-label">NameId Format</label>
                    <div className="input-group">
                        <input type="text" value={editApp.nameIdFormat} onChange={handleInputEditChange} id="nameIdFormat" name="nameIdFormat" className="form-control" />
                        <button onClick={handleUnspecified} className="btn btn-outline-secondary">Unspecified</button>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="messageFormat" className="form-label">Message Format</label>
                    <select value={editApp.messageFormat} onChange={handleInputEditChange} id="messageFormat" name="messageFormat" className="form-select">
                        <option value="0">Unsigned</option>
                        <option value="1">Signed</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="assertionFormat" className="form-label">Assertion Format</label>
                    <select value={editApp.assertionFormat} onChange={handleInputEditChange} id="assertionFormat" name="assertionFormat" className="form-select">
                        <option value="0">Unsigned</option>
                        <option value="1">Signed</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="acceptedResponseDomains" className="form-label">Accepted Response Domains</label>
                    <input type="text" value={editApp.acceptedResponseDomains} onChange={handleInputEditChange} id="acceptedResponseDomains" name="acceptedResponseDomains" className="form-control" />
                    <small>Don't include the domains that are already part of the Service Provider Metadata</small>
                </div>
                <div className="mb-3">
                    <label htmlFor="identityProviderMetadataXML" className="form-label mb-1">Identity Provider Metadata XML</label>
                    {props.unsavedChanges && <div class="alert alert-warning">Save the latest changes to get the up to date Metadata.</div>}
                    <textarea disabled={true} value={identityProviderMetadataXML} id="identityProviderMetadataXML" name="identityProviderMetadataXML" rows="20" className="form-control">
                    </textarea>
                </div>                
                </>
            }
            </>
            }

            {state == 'response' &&  editResponseApp &&
            <>
            <ApplicationSectionHeader app={editResponseApp} section='Response' onCancel={handleCancel} />

            <div className="mb-3">
                <label htmlFor="subjectNameIdentifier" className="form-label">Subject Name Identifier</label>
                <Select
                    id="subjectNameIdentifier"
                    name="subjectNameIdentifier"
                    value={editResponseApp.response ? subjectNameIdentifierOptionsGrouped[1].options.find(o => o.value == editResponseApp.response.subjectNameIdentifier) : ''}
                    onChange={(e) => handleReponseChange(e, 'subjectNameIdentifier', e.value)}
                    options={subjectNameIdentifierOptionsGrouped}
                    className="react-select"
                    classNamePrefix='react-select'
                    formatGroupLabel={data => 
                                            <div>
                                                <span className="d-inline-block">{data.label}</span>
                                                <span className="badge bg-secondary float-end d-inline-block" style={{width: '35px'}}>{data.options.length}</span>
                                            </div>}
                />
            </div>
            
            <div className="mb-3">
                <ApplicationsResponseTable 
                    attributes={editResponseApp.response && editResponseApp.response.attributes ? editResponseApp.response.attributes : []} 
                    crmContactSchemaOptionsGrouped={contactAttributesOptionsGrouped}
                    crmAccountSchemaOptionsGrouped={accountAttributesOptionsGrouped}
                    onChange={(e, value) => handleReponseChange(e, 'attributes', value)}>
                </ApplicationsResponseTable>
            </div>

            <div className="mb-3">
                <label htmlFor="crmCustomActionName" className="form-label">CRM Custom Action Name</label>
                <input type="text" value={editResponseApp.response ? editResponseApp.response.crmCustomActionName : ''} onChange={(e) => handleReponseChange(e, 'crmCustomActionName', e.target.value)} id="crmCustomActionName" name="crmCustomActionName" className="form-control" />
                <small><i>Example: pa_customactionname parameter1=value1,parameter2=value2,parameter3=value3</i></small>
            </div>
            </>
            }  

            {state == 'test' &&  testApp &&
            <>
            <ApplicationSectionHeader app={testApp} section='Test' onCancel={handleCancel} />
            <ApplicationTest application={testApp} unsavedChanges={props.unsavedChanges} />
            </>
            }

            {failedMetadataUpdate &&
            <>
            <div className="fade modal-backdrop show"></div>
            <div role="dialog" className="fade modal show" tabIndex="-1" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title m-3">Error</h5>
                        </div>
                        <div className="modal-body">
                            Metadata update has failed. Please review the URL.
                        </div>
                        <div className="modal-footer text-end">
                            <button onClick={handleCloseMetadataFail} className="btn btn-secondary">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
        </div>
    );
}

export { Applications };