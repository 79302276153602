import React, { useState } from 'react';
import Select from 'react-select';
import { arrayMove } from '../../helpers/generalUtils';

function ApplicationsResponseTable(props) {

    const [moreModaStatus, setMoreModaStatus] = useState({show: false, index: ''});

    function handleAddNew(e) {
        let tempAttributes = props.attributes;
        if (!tempAttributes.length) tempAttributes = new Array();
        tempAttributes.push({ name: '', value: '', entity: 'contact' });
        props.onChange(e, tempAttributes);
    }

    function handleOnChange(e, index, field, value, type) {
        let tempAttributes = props.attributes;
        tempAttributes[index][field] = value;
        if (type) tempAttributes[index].type = type;

        if (field == 'entity')
            handleOnChange(e, index, 'value', '');

        if (field == 'entity' || field == 'value')
        {
            delete tempAttributes[index].format;
            delete tempAttributes[index].multiValueFormat;
            delete tempAttributes[index].ifNull;
        }

        props.onChange(e, tempAttributes);
    }

    function handleOnBlur(e, index, field, value) {
        if (field == 'name') value = value.replaceAll(' ', '_');

        let tempAttributes = props.attributes;
        tempAttributes[index][field] = value;

        props.onChange(e, tempAttributes);
    }

    function handleOnChangeDetail(e, index, field, value) {
        let tempAttributes = props.attributes;
        tempAttributes[index][field] = value;
        props.onChange(e, tempAttributes);
    }    

    function handleDelete(e, index) {
        let tempAttributes = props.attributes;
        tempAttributes.splice(index, 1);
        props.onChange(e, tempAttributes);
    }

    function handleUp(e, index) {
        if (index > 0)
        {
            let tempAttributes = props.attributes;
            arrayMove(tempAttributes, index, index - 1);        
            props.onChange(e, tempAttributes);
        }
    }

    function handleDown(e, index) {
        if (index < props.attributes.length - 1)
        {
            let tempAttributes = props.attributes;
            arrayMove(tempAttributes, index, index + 1);        
            props.onChange(e, tempAttributes);
        }        
    }

    function handleMore(e, index) {
        setMoreModaStatus({show: true, index});
    }

    function closeMore(e, index) {
        setMoreModaStatus({show: false, index: ''});
    }

    function getFieldValue(entity, value) {
        if (entity == 'contact') 
            return props.crmContactSchemaOptionsGrouped[1].options.find(o => o.value == value);
        else
            return props.crmAccountSchemaOptionsGrouped[1].options.find(o => o.value == value);
    }

    return (
            <>
            <div className="mb-3 clearfix">
                <label className="form-label">Attributes</label>
                <table className="table bg-white">
                    <tbody>
                        {(!props.attributes || props.attributes.length == 0) && 
                            <tr>
                                <td colspan="3" className="text-center p-2">
                                    No Attributes to display.
                                </td>
                            </tr>
                        }
                        {props.attributes && props.attributes.length > 0 &&
                            props.attributes.map((att, index) => 
                                <tr>
                                    <td className="p-2" width="25%"><input type="text" placeholder="Name" onChange={(e) => handleOnChange(e, index, 'name', e.target.value)} onBlur={(e) => handleOnBlur(e, index, 'name', e.target.value)} value={att.name} className="form-control" name="name" maxLength={127}></input></td>
                                    <td className="p-2" width="60%">
                                        <div className="d-flex">
                                            <select onChange={(e) => handleOnChange(e, index, 'entity', e.target.value)} value={att.entity} className="form-select" style={{width: "40%"}}>
                                                <option value="contact">Contact</option>
                                                <option value="account">Parent Customer (Account)</option>
                                                <option value="fixedValue">Fixed Value</option>
                                            </select>
                                            {att.entity != 'fixedValue' &&
                                            <Select
                                                name="name"
                                                value={att.value ? getFieldValue(att.entity, att.value) : ''}
                                                onChange={(e) => handleOnChange(e, index, 'value', e.value, e.type)}
                                                options={att.entity == 'contact' ? props.crmContactSchemaOptionsGrouped : props.crmAccountSchemaOptionsGrouped}
                                                placeholder="Value"                                                
                                                className="react-select"
                                                classNamePrefix='react-select'
                                                formatGroupLabel={data => 
                                                                        <div>
                                                                            <span className="d-inline-block">{data.label}</span>
                                                                            <span className="badge bg-secondary float-end d-inline-block" style={{width: '35px'}}>{data.options.length}</span>
                                                                        </div>}
                                            />
                                            }
                                            {att.entity == 'fixedValue' &&
                                            <input type="text" onChange={(e) => handleOnChange(e, index, 'value', e.target.value)} value={att.value} className="form-control" name="value" id="value" />
                                            }
                                        </div>
                                    </td>
                                    <td className="p-2 align-middle text-center">
                                        <button onClick={(e) => handleUp(e, index)} disabled={index == 0} className="btn btn-link px-0"><i className="fas fa-arrow-up" title="Move up"></i></button>
                                        <button onClick={(e) => handleDown(e, index)} disabled={index == props.attributes.length - 1} className="btn btn-link px-1"><i className="fas fa-arrow-down" title="Move down"></i></button>
                                        <button onClick={(e) => handleMore(e, index)} className={"btn btn-link" + (att.entity == "fixedValue" ? " invisible" : "")}><i className="fas fa-ellipsis-v"></i></button>
                                        <button onClick={(e) => handleDelete(e, index)} className="btn btn-link text-danger"><i className="fas fa-trash-alt" title="Delete"></i></button>
                                    </td>
                                </tr>
                            )
                        }
                 
                    </tbody>
                </table>
                <button onClick={handleAddNew} className="btn btn-primary float-end">Add New Attribute</button>
            </div>

            {moreModaStatus.show && 
            <>
            <div className="fade modal-backdrop show"></div>
            <div role="dialog" className="fade modal show" tabIndex="-1" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title m-3">Attribute Details: {props.attributes[moreModaStatus.index].entity}.{props.attributes[moreModaStatus.index].value}</h5>
                        </div>
                        <div className="modal-body">
                            {(props.attributes[moreModaStatus.index].type == 'Lookup' || props.attributes[moreModaStatus.index].type == 'Customer' || props.attributes[moreModaStatus.index].value == 'webroles') &&
                            <div className="mb-3">
                                <label className="form-label" htmlFor="lookup">Format</label>
                                <select onChange={(e) => handleOnChangeDetail(e, moreModaStatus.index, 'format', e.target.value)} value={props.attributes[moreModaStatus.index].format} id="lookup" className="form-select">
                                    <option value="name">Name</option>
                                    <option value="id">Id</option>
                                </select>
                            </div>
                            }
                            {(props.attributes[moreModaStatus.index].type == 'Picklist' || props.attributes[moreModaStatus.index].type == 'Virtual') &&
                            <div className="mb-3">
                                <label className="form-label" htmlFor="lookup">Format</label>
                                <select onChange={(e) => handleOnChangeDetail(e, moreModaStatus.index, 'format', e.target.value)} value={props.attributes[moreModaStatus.index].format} id="picklist" className="form-select">
                                    <option value="value">Value</option>
                                    <option value="number">Number</option>
                                </select>
                            </div>
                            }
                            {props.attributes[moreModaStatus.index].type == 'Boolean' &&
                            <div className="mb-3">
                                <label className="form-label" htmlFor="lookup">Format</label>
                                <select onChange={(e) => handleOnChangeDetail(e, moreModaStatus.index, 'format', e.target.value)} value={props.attributes[moreModaStatus.index].format} id="boolean" className="form-select">
                                    <option value="text">true/false</option>
                                    <option value="numeric">1/0</option>
                                </select>
                            </div>
                            }
                            {(props.attributes[moreModaStatus.index].value == 'webroles' || props.attributes[moreModaStatus.index].type == 'Virtual') && 
                            <div className="mb-3">
                                <label className="form-label" htmlFor="format">Return as</label>
                                <select onChange={(e) => handleOnChangeDetail(e, moreModaStatus.index, 'multiValueFormat', e.target.value)} value={props.attributes[moreModaStatus.index].multiValueFormat} id="format" name="format" className="form-select">
                                    <option value="list">List</option>
                                    <option value="stringSemicolonSeparated">String separated by Semicolon</option>
                                    <option value="stringCommaSeparated">String separated by Comma</option>
                                </select>
                            </div>
                            }
                            <div className="mb-3">
                                <label className="form-label" htmlFor="null">If value is null</label>
                                <select onChange={(e) => handleOnChangeDetail(e, moreModaStatus.index, 'ifNull', e.target.value)} value={props.attributes[moreModaStatus.index].ifNull} id="null" className="form-select">
                                    <option value="null">Return null</option>
                                    <option value="empty">Return empty</option>
                                    <option value="exclude">Exclude attribute</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer text-end">
                            <button onClick={closeMore} className="btn btn-secondary">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
            </>
    );
}

export { ApplicationsResponseTable };