import React from 'react'
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import reportWebVitals from './helpers/reportWebVitals';

import App from './App'

import configureStore from './configure-store';

const initialState = global.window && global.window.__INITIAL_STATE__ 
const store = configureStore(initialState);

ReactDOM.hydrate(
    <Provider store={store}>
        <App store={store}/>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
