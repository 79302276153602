import React from 'react';
import Editor from "@monaco-editor/react";

function ThemesSiteFooter(props) {

    function handleChange(e) { 
        let value = e.target.value;
        let key = e.target.name;
        
        if (e.target.type == "checkbox") value = e.target.checked;

        props.onChange('site', 'footer', key, value);
    }

    function handleHtmlChange(e) { 
        let value = e;
        let key = 'html';        

        props.onChange('site', 'footer', key, value);
    }

    return (
            <>
                {props.settings &&
                <>
                <h4 className="text-primary">Footer</h4>
                <hr />
                <div className="mb-3">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="display" name="display" checked={props.settings.footer.display} onChange={(e) => handleChange(e)}></input>
                        <label className="form-check-label" htmlFor="display">Display</label>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="homeUrl" className="form-label">Text</label>
                    <input type="text" className="form-control" name="text" id="text" value={props.settings.footer.text} onChange={(e) => handleChange(e)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="html" className="form-label">Html</label>
                    <Editor
                        height="40vh"
                        defaultLanguage="html"
                        name="html" 
                        id="html"
                        value={props.settings.footer.html} 
                        onChange={(e) => handleHtmlChange(e)}
                    />
                </div>
                </>
                }
            </>
    )
}

export { ThemesSiteFooter };
