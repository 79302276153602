import { tenantInfoService } from '../services';

export const tenantInfoActions = {
    getCustomer,
    getCrm,
    getUrls,
    getSamlCertificate,
    getCrmSchema,
    getContactId,
    getApplicationSPMetadata
};

function getCustomer() {
    return tenantInfoService.getCustomer()
        .then(
            customer => {                     
                return customer.data;
            },
            error => {
                return error.toString();
            }
        );
};

function getCrm() {
    return tenantInfoService.getCrm()
        .then(
            crmInfo => {                     
                return crmInfo.data;
            },
            error => {
                return error.toString();
            }
        );
};

function getUrls() {
    return tenantInfoService.getUrls()
        .then(
            urls => {                     
                return urls.data;
            },
            error => {
                return error.toString();
            }
        );
};

function getSamlCertificate() {
  
    return tenantInfoService.getSamlCertificate()
    .then(
        samlCertificate => {                     
            return samlCertificate.data;
        },
        error => {
            return error.toString();
        }
    );
};

function getCrmSchema(entityNames) {
  
    return tenantInfoService.getCrmSchema(entityNames)
    .then(
        fields => {                     
            return fields.data;
        },
        error => {
            return error.toString();
        }
    );
};

function getContactId(contactNumber) {
  
    return tenantInfoService.getContactId(contactNumber)
    .then(
        contactId => {                     
            return contactId.data;
        },
        error => {
            return error.toString();
        }
    );
};

function getApplicationSPMetadata(url) {
  
    return tenantInfoService.getApplicationSPMetadata(url)
    .then(
        metadata => {                     
            return metadata.data;
        },
        error => {
            return null;
        }
    );
};