import React, { useEffect, useState } from 'react';

function SamlIntegration(props) {

    const [samlCertificate, setSamlCertificate] = useState([]);

    useEffect(() => {
        setSamlCertificate(props.samlCertificate);
    }, []);

    function handleChange(e) { 
        let value = e.target.value;
        let key = e.target.name;

        if (e.target.type == "checkbox") value = e.target.checked;

        props.onChange('site', 'sso', 'saml', key, value);
    }

    return (
        <>
            {props.settings && 
            <>
            <h4 className="text-primary">SAML Method</h4>
            <hr />
            <div className="mb-3">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="integrationEnabled" name="integrationEnabled" checked={props.settings.integrationEnabled} onChange={(e) => handleChange(e)}></input>
                    <label className="form-check-label" htmlFor="integrationEnabled">Integration enabled</label>
                </div> 
            </div>
            <hr />
            <h5 className="text-primary">Certificates</h5>
            <table class="table table-bordered bg-white mb-4 mt-3"> 
                <tbody>
                    <tr>
                        <th scope="row" className="w-25">Certificate</th>
                        <td>{samlCertificate.samlCertificate}</td>                    
                    </tr>
                    <tr>
                        <th scope="row">Public Key</th>
                        <td>{samlCertificate.publicKey}</td>
                    </tr>
                </tbody>
            </table>
            </>
            }
        </>
    )
}

export { SamlIntegration };
