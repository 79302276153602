
export const getUserAcronym = (firstName, lastName) => {
    return ((typeof firstName == 'string' ? firstName.charAt(0) : '') + (typeof lastName == 'string' ? lastName.charAt(0) : '')).toUpperCase();
  };
  
export const stringToHslColor = (str) => {
    var s = 75;
    var l = 50;
    var hash = 0;
    str = str.toLowerCase();
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const previewUrl = (url) => {
    if (url)
        window.open((!url.match(/^[a-zA-Z]+:\/\//) ? "http://" : "") + url, "_blank");
}

export const isEmptyOrSpaces = (str) => {
  return !str || str === null || str.match(/^ *$/) !== null;
}

/**
 * Get the value of specific query string parameter
 * @param {string} name Query String Name
 * @returns a of specific value of query string parameter
 */
 export const getParams = (name) => {

    let params;
  
    if(window && window.location){
      params = new URLSearchParams(window.location.search.substring(1));
      if(params){
        params = params.get(name);
      }
    }
  
    return params;
}

export const arrayMove = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

export const newGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {    
    const crypto = window.crypto || window.msCrypto;
    var arr = new Uint32Array(1);
    crypto.getRandomValues(arr); // Compliant for security-sensitive use cases  
    var rndNumber = arr[0] * Math.pow(2,-32);
    const r = rndNumber * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}