import React from 'react';

function ThemesSiteNav(props) {

    function handleClick(level3) { 
        props.onClick(level3);
    }

    return (
        <>
        <ul className="nav border-bottom bg-primary sticky-top p-2 mb-3" role="tablist">
            {props.displaySecondLevelIcon && <i className="fas fa-globe fa-lg text-light mt-1 p-2"></i>}
            <li className={"nav-item " + (props.active == 'general' ? 'border-bottom' : '')}>
                <a role="button" onClick={() => handleClick('general')} className="nav-link text-light">General</a>
            </li>
            <li className={"nav-item " + (props.active == 'images' ? 'border-bottom' : '')}>
                <a role="button" onClick={() => handleClick('images')} className="nav-link text-light">Images</a>
            </li>
            <li className={"nav-item " + (props.active == 'styling' ? 'border-bottom' : '')}>
                <a role="button" onClick={() => handleClick('styling')} className="nav-link text-light">Styling</a>
            </li>
            <li className={"nav-item " + (props.active == 'header' ? 'border-bottom' : '')}>
                <a role="button" onClick={() => handleClick('header')} className="nav-link text-light">Header</a>
            </li>
            <li className={"nav-item " + (props.active == 'footer' ? 'border-bottom' : '')}>
                <a role="button" onClick={() => handleClick('footer')} className="nav-link text-light">Footer</a>
            </li>
        </ul>
        </>
    )
}

export { ThemesSiteNav };
