import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../actions';
import { Avatar } from './common/Avatar'
import { ConfigurationModal } from './admin/ConfigurationModal';
import Skeleton from 'react-loading-skeleton';

function User(props) {

    const generalSettings = useSelector(state => state.generalSettings);
    const loginSettings = useSelector(state => state.loginSettings);
    const [signingOut, setSigningOut] = useState(false);
    const dispatch = useDispatch();

    function handleSignOut() {
        setSigningOut(true);
        dispatch(userActions.logout());
    }

    return (
        <React.Fragment>
            <div className="content-box text-center">
                <Skeleton className="d-none d-md-inline-flex bg-primary rounded-0 loading-bar" style={{visibility: signingOut ? 'visible' : 'hidden'}} />

                <div className="p-4">
                    <div className="mb-4">
                        <img src={generalSettings.images.logo} style={generalSettings.images.logoWidth ? {width: generalSettings.images.logoWidth} : {}} />
                    </div>
                    <div className="mb-4">
                        <div className="d-flex mb-1">
                            <div className="mx-auto">
                                <Avatar user={props.user} size="l"></Avatar>
                            </div>
                        </div>
                        <h5 className="mb-1">{props.user.firstName + ' ' + props.user.lastName}</h5>
                        <h6 className="text-muted">{props.user.email}</h6>
                    </div>
                    <div>
                        <button onClick={handleSignOut} disabled={signingOut} className="btn btn-outline-primary btn-sm px-5">
                            {!signingOut ? loginSettings.formTexts.signOutButton : loginSettings.formTexts.signingOutButton }
                        </button>
                    </div>                
                </div>
            </div>
            <div className="user-footer p-3">
                <a role="button" href={loginSettings.urls.changePassword} className="text-decoration-none">
                    <i className="fas fa-key me-1"></i>{loginSettings.formTexts.changePasswordLink}
                </a>
                {props.user.isAdmin &&
                <div className="d-none d-md-block float-end">
                    <ConfigurationModal></ConfigurationModal>
                </div>
                }
            </div>
        </React.Fragment> 
    );
};

export { User };
