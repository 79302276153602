import { samlSimulationService } from '../services';

export const spSimulationActions = { 
    login
};

function login(applicationName, contactId, idpInitiated) {
    return samlSimulationService.login(applicationName, contactId, idpInitiated)
        .then(
            loginSimulation => {                     
                return loginSimulation.data;
            },
            error => {
                return error.toString();
            }
        );
};