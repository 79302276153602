import axios from "axios";
import cookie from 'react-cookies';
import {initializeAxiosMockAdapter, isMockEnabled,} from "../mock/mock.config";
import { checkStatusCode, errorNoServerResponse } from "./utils";
import { commonConstants, systemConstants } from '../constants';

let instance = axios.create({
    baseURL: `${commonConstants.BASE_URL}`,    
    headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-Request-Correlation" : systemConstants.CORRELATION_KEY,
        'csrf-token': cookie.load(systemConstants.CSRF_COOKIE_NAME)
    }
});

if (isMockEnabled()) {
    initializeAxiosMockAdapter(instance);
}

instance.interceptors.response.use((response) => {
    return response;
}, (error) => { 
  sendLogError(error);
  if (error.response && error.response.data) {
      return checkStatusCode(error.response.status, error.response.data)
  }
  return errorNoServerResponse();
});

const sendLogError = (error) => {
  console.log(error);
}

export const hasError = response => response.ClassName && response.ClassName.indexOf("Exception") > -1;

export const getErrorMessage = response => response.Message;

export const http = instance;
