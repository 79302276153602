export const authenticationStateModel = {
    loggedIn: false, 
    user: {}
};

export const generalSettingsStateModel = {
    images: {
        logo: 'https://mx365dev.ptassoc.com/Portals/0/logo.png'
    }
}

export const loginSettingsStateModel = {
    lastModifiedOn: '',
    lastModifiedBy: '',
    title: "Sign in",
	showTitle: true,
    subtitle: "",
    showSubtitle: false,
    instructionsText: "",
    prompts: {
        webLoginName: "Username",
        webLoginPassword: "Password"
    },
    options: {
        displayRememberMeOnThisComputer: true
    },
    urls: {
        signInHelp: "https://mx365dev.ptassoc.com/Security/Sign-In-Help",
        createNewUser: "https://mx365dev.ptassoc.com/Security/Create-New-User-Account",
        changePassword: "http://mx365dev.ptassoc.com/My-Account/My-Settings/My-Password",
		signOut: "http://mx365dev.ptassoc.com/Portals/_default/Skins/MXOnline/pages/SignOut.aspx"
    },
    instructions: {
        signInHelp: ""
    },
    formTexts: {
        signInHelpLink: "Forgot User name or Password",
        createNewUserLink: "Register for this site as a new user",
        signInButton: "Sign In",
        signingInButton: "Signing In...",
        rememberMeOnThisComputer: "Keep me signed in if I close the browser",
        changePasswordLink: "Change Password",
		signOutLink: "Sign Out"
    },
    messages: {
        userNameRequired: "You didn't provide an username.",
        passwordRequired: "Password is a required field.",
        authenticationError: "The Username and password you entered do not match any accounts on record."
    }
}