import React, { useState, useEffect} from 'react';
import cookie from 'react-cookies';
import Dropzone from 'react-dropzone-uploader';
import { commonConstants } from '../../constants/common.constants';
import { systemConstants } from '../../constants/system.constants';
import { assetTypeConstants } from '../../constants/assetType.constants';
import { isEmptyOrSpaces } from '../../helpers/generalUtils';

function ImageUploader(props) {

    const [uploading, setUploading] = useState(false);
    const [rejectedFileUpload, setRejectedFileUpload] = useState(false);
    const [pasteImageUrl, setPasteImageUrl] = useState('');
    const [uploadImageUrl, setUploadImageUrl] = useState('');
    const [imageSource, setImageSource] = useState('');

    useEffect(() => {  
      if (props.imageUrl && props.imageUrl.startsWith('http') && !props.imageUrl.includes(commonConstants.ASSETS_GET_SERVICE_URL))
      {
          setPasteImageUrl(props.imageUrl);
          setImageSource('url');
      }
      else
      {
          setUploadImageUrl(props.imageUrl);
          setImageSource('upload');
      }
    }, []);

    function getUploadParams( {file} ) {
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('themeId', props.theme);
        return { 
            url: `${commonConstants.BASE_URL}/api/assets/upload`, 
            body,
            headers: { 
              "X-Request-Correlation": systemConstants.CORRELATION_KEY,
              "csrf-token": cookie.load( systemConstants.CSRF_COOKIE_NAME )
            }
        };
    }

    function handleChangeStatus(fileWithMeta, status) {   
        switch (status){
          case 'uploading':
            setUploading(true);
            break;
          case 'done':
            let previewUrl = `${commonConstants.BASE_URL}/${commonConstants.ASSETS_GET_SERVICE_URL}?type=${assetTypeConstants.TEMP}&filePath=${getXhrResult(fileWithMeta.xhr).commitToken}.file`;
            setUploading(false);
            setUploadImageUrl(previewUrl);
            props.onImageUpload(getXhrResult(fileWithMeta.xhr).commitToken, previewUrl);
            fileWithMeta.remove();
            break;
          case 'rejected_file_type':
            setRejectedFileUpload(true)
            setTimeout(() => { setRejectedFileUpload(false) }, 5000);
            break;
          default:
            break;
        }
    }

    function handleImageSourceChange(imageSourceChange) {
        setImageSource(imageSourceChange);
        props.onImageSourceChange(imageSourceChange);
    }

    function handleImageUrlChange(e) {
      setPasteImageUrl(e.target.value);
      props.onImageUrlChange(e.target.value);
    }

    function getXhrResult(xhr) {
        if (xhr) {
          if (xhr.readyState === 4) {
            return JSON.parse(xhr.response);
          }  
        }
    }

    const imageStyle = props.width ? {width: props.width} : {};
    imageStyle.maxWidth = "100%";
    imageStyle.maxHeight = "100%";
    
    return (
        <div className="image-uploader">          
            <div className="bg-white border border-dark d-flex justify-content-center align-items-center border-radius rounded mb-3 p-2" style={{height: "100px"}}>
                {uploading && <i className="fas fa-spinner fa-2x fa-spin"></i>}
                {<img src={pasteImageUrl} className={(imageSource === 'url' && !isEmptyOrSpaces(pasteImageUrl) && !uploading ? "" : "d-none")} style={imageStyle} alt={pasteImageUrl}/>}
                {<img src={(uploadImageUrl ? (uploadImageUrl.includes(commonConstants.ASSETS_GET_SERVICE_URL) ? uploadImageUrl : `${commonConstants.BASE_URL}/${commonConstants.ASSETS_GET_SERVICE_URL}?type=${assetTypeConstants.IMAGE}&filePath=${props.theme}/${uploadImageUrl}&v=${props.timestampVersion}`) : '')} className={imageSource === 'upload' && uploadImageUrl && !uploading ? "" : "d-none"} style={imageStyle} alt=""/>}
                {((imageSource === 'upload' && !uploadImageUrl) || (imageSource === 'url' && isEmptyOrSpaces(pasteImageUrl)) || imageSource == 'noImage') && !uploading && <div className="text-muted">No image</div>}
            </div>

            {rejectedFileUpload && <div className="alert alert-danger">Selected file type is not supported</div>}
            <div className="mb-3">
                <a role="button" onClick={() => handleImageSourceChange('url')} className={"me-2 text-decoration-none" + (imageSource == 'url' ? " fw-bold text-body" : "")}>Paste an URL</a>
                |
                <a role="button" onClick={() => handleImageSourceChange('upload')} className={"mx-2 text-decoration-none" + (imageSource == 'upload' ? " fw-bold text-body" : "")}>Upload a file</a>
                {props.displayNoImage &&
                <>
                |
                <a role="button" onClick={() => handleImageSourceChange('noImage')} className={"ms-2 text-decoration-none" + (imageSource == 'noImage' ? " fw-bold text-body" : "")}>Delete image</a>
                </>
                }
            </div>

            {imageSource == 'upload' &&
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                accept="image/*"
                minSizeBytes="1"
                maxSizeBytes="1073741824"
                maxFiles="1" 
                multiple={false}    
                SubmitButtonComponent={null}
                canRemove={true}
                inputContent="Drop file or click here"                
                disabled={files => files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status))}
            />
            }
            {imageSource == 'url' &&
              <input type="text" className="form-control" name="imageUrl" id="imageUrl" value={pasteImageUrl} onChange={handleImageUrlChange} />
            }
        </div>
    );
}

export { ImageUploader };

