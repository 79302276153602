import React from 'react';
import { getUserAcronym, stringToHslColor } from '../../helpers/generalUtils'

function Avatar(props) {
    let pixelsSize;
    switch (props.size)
    {
        case "xl":
            pixelsSize = "180px";
            break;
        case "l":
            pixelsSize = "75px";
            break;
        case "md":
            pixelsSize = "45px";
            break;
        default:
            pixelsSize = "30px";
            break;
    }

    let fontStyle = (props.size == "xl" ? "display-2" : (props.size == "l" ? "display-6" : ""));
    let backgroundColor = stringToHslColor(props.user.firstName + " " + props.user.lastName);

    return (
        <div className="d-flex justify-content-center align-items-center rounded-circle" style={{width: pixelsSize, height: pixelsSize, backgroundColor: backgroundColor}}>
            <span className={"text-light " + fontStyle}>{getUserAcronym(props.user.firstName, props.user.lastName)}</span>
        </div>
    );
}

export { Avatar };
