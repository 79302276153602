// ** 
// * Wrapped action methods in an alertActions object at the top of the file 
// * The idea is to see (on easy way) all available actions at a glance and 
// * simplifies importing them into other files. 
// **

import { alertConstants } from '../constants';

export const alertActions = {
    info,
    error,
    clear
};

function info(message) {
    return { type: alertConstants.INFO, message };
}

function error(message) {
    return { type: alertConstants.ERROR, message };
}

function clear() {
    return { type: alertConstants.CLEAR };
}