import { alertConstants } from '../constants';

export function alert(state = {}, action) {
    switch (action.type) {
        case alertConstants.INFO:
            return {
                type: action.type,
                message: action.message
            };
        case alertConstants.ERROR:
            return {
                type: action.type,
                message: action.message
            };
        case alertConstants.CLEAR:
            return {
                type: null,
                message: ''
            };
        default:
            return state
    }
}