import React, { useEffect, useState } from 'react';
import { isEmptyOrSpaces } from '../../helpers/generalUtils';
import { isDefaultTheme, isThemeInUse } from '../../helpers/themes';

function LeftMenu(props) {

    const [modalOpen, setModalOpen] = useState(false);
    const [inputs, setInputs] = useState({});
    const [displayNameRequiredValidation, setDisplayNameRequiredValidation] = useState(false);
    const [displayDuplicateValidation, setDisplayDuplicateValidation] = useState(false);
    const [duplicatingThemeId, setDuplicatingThemeId] = useState('');
    const [renamingThemeId, setRenamingThemeId] = useState('');    
    const [deletingThemeId, setDeletingThemeId] = useState('');

    useEffect(() => {
        if (displayNameRequiredValidation) setDisplayNameRequiredValidation(false);
        if (displayDuplicateValidation) setDisplayDuplicateValidation(false);
    }, [inputs]);

    function handleMenuClick(level1, level2, theme) { 
        props.onMenuClick(level1, level2, theme);
    }

    function handleInputChange(e) {
        var { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleAddNewTheme() {
        setModalOpen(true);
    }    

    function handleDuplicateTheme(e, themeId) {
        setModalOpen(true);
        setDuplicatingThemeId(themeId);
    }

    function handleRenameTheme(e, themeId, themeName) {
        setModalOpen(true);
        setInputs(inputs => ({ ...inputs, name: themeName }));
        setRenamingThemeId(themeId);
    }

    function handleDeleteTheme(e, themeId) {
        setDeletingThemeId(themeId);
    }

    function handleConfirmDeleteTheme(e) {
        e.stopPropagation();
        props.onThemeDelete(deletingThemeId);
        setDeletingThemeId('');
    }

    function handleCancelDeleteTheme(e) {
        setDeletingThemeId('');        
    }

    function handleSaveAdd(e) {
        let name = inputs.name.trim();

        if (isEmpty(name)) return;
        if (isDuplicate(name)) return;
        setModalOpen(false);

        // Clear inputs
        setInputs([]);

        // Update parent's state
        props.onThemeAdd(e, name);
    }
    
    function handleSaveDuplicate(e){
        let name = inputs.name.trim();

        if (isEmpty(name)) return;
        if (isDuplicate(name)) return;
        setModalOpen(false);
        setDuplicatingThemeId('');

        // Clear inputs
        setInputs([]);

        // Update parent's state
        props.onThemeDuplicate(e, duplicatingThemeId, name);
    }

    function handleSaveRename(e) {
        let name = inputs.name.trim();

        if (isEmpty(name)) return;
        if (isDuplicate(name)) return;
        setModalOpen(false);
        setRenamingThemeId('');

        // Clear inputs
        setInputs([]);

        // Update parent's state
        props.onThemeRename(e, renamingThemeId, name);
    }

    function isEmpty(name) {
        if (isEmptyOrSpaces(name)) {
            setDisplayNameRequiredValidation(true);
            return true;
        }

        return false;
    }

    function isDuplicate(name) {
        if (props.settings.themes.filter(t => t.name.toLowerCase() === name.toLowerCase() && t.id != renamingThemeId).length > 0) {
            setDisplayDuplicateValidation(true);
            return true;
        }

        return false;
    }

    function handleCancel(e) {
        setModalOpen(false);
        setRenamingThemeId('');
     
        // Clear inputs
        setInputs([]);
    }    

    let deleteTheme = props.settings.themes.find(theme => theme.id == deletingThemeId);

    return (
        <>
            <div className="col-1 m-3 ms-2 mt-0 mh-100 g-0" style={{width: "250px"}}>
                <div className="bg-white border">
                    <h5 className="p-3 mb-0 border-bottom"><i className="fas fa-sitemap fa-sm me-2"></i>Site</h5>
                    <a role="button" onClick={() => handleMenuClick('site', 'general')} className={"p-3 border-bottom text-decoration-none d-block " + (props.level1 == 'site' && props.level2 == 'general' ? "bg-primary text-white" : "")}><i className="fas fa-angle-right fa-sm me-2"></i>General</a>
                    <a role="button" onClick={() => handleMenuClick('site', 'sso')} className={"p-3 border-bottom text-decoration-none d-block " + (props.level1 == 'site' && props.level2 == 'sso' ? "bg-primary text-white" : "")}><i className="fas fa-angle-right fa-sm me-2"></i>SSO Integrations</a>
                    <a role="button" onClick={() => handleMenuClick('site', 'about')} className={"p-3 border-bottom text-decoration-none d-block " + (props.level1 == 'site' && props.level2 == 'about' ? "bg-primary text-white" : "")}><i className="fas fa-angle-right fa-sm me-2"></i>About</a>
                    <h5 className="p-3 mb-0 border-bottom"><i className="fas fa-file-alt fa-sm me-2"></i>Themes</h5>
                    <a role="button" onClick={handleAddNewTheme} className={"p-3 border-bottom text-decoration-none d-block "}><i className="fas fa-angle-right fa-sm me-2"></i>Add New Theme</a>
                    {props.settings.themes.map(theme =>
                        <div role="button" onClick={() => handleMenuClick('themes', 'site', theme.id)} className={"clearfix p-3 border-bottom " + ((props.level1 == 'themes' && props.selectedThemeId == theme.id) ? "bg-primary text-white" : "text-primary")}>
                            <i className="fas fa-angle-right fa-sm me-2"></i>{theme.name}
                            {isDefaultTheme(theme.id, props.settings) && <span class={`badge ms-2 ${((props.level1 != 'themes' || props.selectedThemeId != theme.id) ? 'bg-primary' : 'bg-light text-primary')}`}>Default</span>}
                            {props.settings.site.sso.applications.filter(app => app.theme == theme.id).length > 0 && <span class={`badge ms-2 ${((props.level1 != 'themes' || props.selectedThemeId != theme.id) ? 'bg-primary' : 'bg-light text-primary')}`}>In Use</span>}
                            <div className={"dropdown float-end" + (props.themeOpenedDropdown == theme.id ? ' show' : '')}>
                                <a role="button" onClick={(e) => props.onThemeOpenDropdown(e, theme.id)}>
                                    <i className="fas fa-ellipsis-h me-2"></i>
                                </a>
                                <ul className={"dropdown-menu" + (props.themeOpenedDropdown == theme.id ? ' show' : '')}>
                                    {!isDefaultTheme(theme.id, props.settings) && <li><button onClick={(e) => props.onSetAsSiteTheme(e, theme.id)} className="dropdown-item">Set as Default Theme</button></li>}
                                    <li><button onClick={(e) => handleDuplicateTheme(e, theme.id)} className="dropdown-item">Duplicate</button></li>
                                    <li><button onClick={(e) => handleRenameTheme(e, theme.id, theme.name)} className="dropdown-item">Rename</button></li>
                                    {!isThemeInUse(theme.id, props.settings) && <li><button onClick={(e) => handleDeleteTheme(e, theme.id)} className="dropdown-item">Delete</button></li>}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {modalOpen &&
            <>
            <div className="fade modal-backdrop show"></div>
            <div role="dialog" className="fade modal show" tabIndex="-1" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title m-3">Theme</h5>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <input placeholder="Name" onChange={handleInputChange} value={inputs.name} className={"form-control" + (displayNameRequiredValidation || displayDuplicateValidation ? " is-invalid" : "")} type="textbox" id="name" name="name"></input>
                                {(displayNameRequiredValidation || displayDuplicateValidation) && 
                                <div className="invalid-feedback">
                                    {displayNameRequiredValidation && <>Name is required.</>}
                                    {displayDuplicateValidation && <>There's another Theme named {inputs.name}. Please change Theme Name and try again.</>}
                                </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer text-end">
                            <button onClick={renamingThemeId ? handleSaveRename : duplicatingThemeId ? handleSaveDuplicate : handleSaveAdd} className="btn btn-primary me-2">{renamingThemeId ? "Rename" : duplicatingThemeId ? "Duplicate" : "Add"}</button>
                            <button onClick={handleCancel} className="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }

            {deletingThemeId &&
            <>
            <div className="fade modal-backdrop show"></div>
            <div role="dialog" className="fade modal show" tabIndex="-1" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title m-3">Theme</h5>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                Are you sure you want to delete <b><i>{deleteTheme.name}</i></b> Theme?
                            </div>
                        </div>
                        <div className="modal-footer text-end">
                            <button onClick={handleConfirmDeleteTheme} className="btn btn-primary me-2">Delete</button>
                            <button onClick={handleCancelDeleteTheme} className="btn btn-secondary">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }

        </>
    )
}

export { LeftMenu };
