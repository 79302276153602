import { idpService } from '../services';

export const idpActions = {
    metadata
};

function metadata(applicationName) {

       return idpService.metadata(applicationName)
            .then(
                response => { return response.data; },
                error => { return error.toString(); }
            );
};