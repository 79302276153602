import React from 'react';

function ThemesSiteGeneral(props) {

    function handleChange(e) { 
        let value = e.target.value;
        let key = e.target.name;
        
        if (e.target.type == "checkbox") value = e.target.checked;

        props.onChange('site', key, value);
    }

    return (
            <>
                {props.settings &&
                <>
                <h4 className="text-primary">General</h4>
                <hr />
                <div className="mb-3">
                    <label htmlFor="title" className="form-label">Site title</label>
                    <input type="text" className="form-control" name="title" id="title" value={props.settings.title} onChange={(e) => handleChange(e)} />
                </div>
                </>
                }
            </>
    )
}

export { ThemesSiteGeneral };
