import React from 'react';
import { capitalizeFirstLetter } from '../../helpers/generalUtils';
import { useSelector } from 'react-redux';

function Header(props) {

    const productVersion = useSelector(state => state.generalSettings.productVersion);


    return (
        <>
            <div className="row p-3">
                <div className="col text-primary">
                    {props.lastModifiedOn &&
                    <span className="text-primary">
                        Last modified on: {props.lastModifiedOn}, by {props.lastModifiedBy}
                    </span>}
                </div>
                <div className="col text-end">
                    <small>Build Number {productVersion}</small>
                </div>
            </div>   
            <div className="row border-top border-bottom p-3">
                <div className="col">
                    <div className="float-start me-1">
                        <h3 className="m-0">Configuration</h3>
                        <h6 className="text-primary fw-bold mb-0">{capitalizeFirstLetter(props.level1)} <i className="fas fa-angle-right fa-sm"></i> {props.level1 == 'themes' ? props.themeName : (props.level2 == 'sso' ? 'SSO Integrations' : capitalizeFirstLetter(props.level2))}</h6>
                    </div>
                    <div className="mt-2 text-muted">
                        - {props.unsavedChanges ? "Unsaved" : "Saved"}
                    </div>
                </div>
                <div className="col text-end pt-2">
                    <button type="button" onClick={() => props.onSave()} disabled={props.isSaving || !props.unsavedChanges} className="btn btn-outline-primary me-2">
                        <i className="fas fa-save fa-lg"></i> Save
                        {props.isSaving && 
                        <i className="fas fa-spinner fa-spin ms-2"></i>
                        }
                    </button>
                    <button onClick={() => props.onClose()} className="btn btn-outline-secondary"><i className="fas fa-times fa-lg"></i> Close</button>
                </div>
            </div>
        </>
    )
}

export { Header };
