import React from 'react';
import { themeUses, isThemeInUse } from '../../helpers/themes';

function ThemesAbout(props) {  
    
    let uses = themeUses(props.themeId, props.settings);

    return (
            <>
            <h4 className="text-primary mt-3">About</h4>
            <hr />
            {isThemeInUse(props.themeId, props.settings) && 
            <>
            This theme is used on {uses.length} place{uses.length > 1 ? 's' : ''}:
            <table class="table table-bordered bg-white mt-3"> 
                <tbody>
                    {uses.map(use => <tr><td>{use}</td></tr>)}
                </tbody>
            </table>
            </>
            }

            {!isThemeInUse(props.themeId, props.settings) && <>This theme is not in use.</>}
            </>
    )
}

export { ThemesAbout };
