import React from 'react';

function ApplicationSectionHeader(props) {    

    return (
        <div className="sticky-top bg-light">
            <h4 className="text-primary d-inline"><i className="fas fa-window-maximize me-2"></i>Application: {props.app.name}
            <span className="badge bg-secondary mx-2 py-2">{props.app.method.toUpperCase()}</span>- {props.section}</h4>
            <a role="button" onClick={props.onCancel} className="float-end text-decoration-none">
                <i className="fas fa-angle-left me-1"></i>Back to Applications
            </a>
            <hr />
        </div>
    )
}

export { ApplicationSectionHeader };
